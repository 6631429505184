import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { device } from 'component-library/styles/device';
import { StaticImage } from 'gatsby-plugin-image';
import { getLegalCopy } from 'page-components/index/hero/content';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React, { FC } from 'react';
import styled from 'styled-components';

import { HeroProps } from '.';
import { body, bulletPoints, heading } from './content';
import { BookConsultationButton } from './shared';

const HeroText = styled(Flex)`
  padding: 56px 20px;
`;

const HeroImage = styled.div`
  z-index: 2;

  ${`@media ${device.md}`} {
    width: 400px;
    align-self: flex-end;
`;

export const HeroSmall: FC<HeroProps> = ({ affiliate }) => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <HeroText column>
      <Heading marginBottom={16} variant={4}>
        {heading}
      </Heading>
      <Body marginBottom={24} variant={3}>
        {body}
      </Body>
      <Flex marginBottom={24} column>
        {bulletPoints.map((bulletPoint, idx) => (
          <Flex key={idx} marginBottom={16} alignItems="center">
            <StyledCheckmark />
            <Body variant={3}>{bulletPoint}</Body>
          </Flex>
        ))}
      </Flex>
      <BookConsultationButton marginBottom={32} />
      <Body variant={5} marginBottom={32}>
        {getLegalCopy(affiliate)}
      </Body>
      <HeroImage>
        <StaticImage
          src="../../images/affiliate-hero.webp"
          alt="couple-smiling"
        />
      </HeroImage>
    </HeroText>
  </ShowOn>
);
