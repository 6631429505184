import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import { SectionLarge } from 'page-components/shared/section/SectionLarge';
import { SectionSmall } from 'page-components/shared/section/SectionSmall';
import React from 'react';

const heading = "We'll give you peace of mind";
const bulletPoints = [
  'See what you can comfortably spend each month',
  'Get an investment strategy designed to maintain your lifestyle',
  'Anticipate your changing healthcare needs and costs',
  'Plan to navigate inflation, taxes, and unexpected events',
];

export const PeaceOfMind = () => (
  <>
    <ShowOn screens={[Screen.Sm, Screen.Md]}>
      <SectionSmall
        backgroundColor={colors.teal}
        image={
          <StaticImage
            src="../images/peace-of-mind.webp"
            alt="safe-to-spend-product"
          />
        }
        withImagePadding
      >
        <Heading variant={4} marginBottom={32}>
          {heading}
        </Heading>
        {bulletPoints.map((bulletPoint, idx) => (
          <Flex key={idx} marginBottom={16} alignItems="center">
            <StyledCheckmark />
            <ResponsiveBody variant={3}>{bulletPoint}</ResponsiveBody>
          </Flex>
        ))}
      </SectionSmall>
    </ShowOn>
    <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
      <SectionLarge
        backgroundColor={colors.teal}
        image={
          <StaticImage
            src="../images/peace-of-mind.webp"
            alt="safe-to-spend-product"
            width={600}
          />
        }
        imageAlignment="right"
      >
        <ResponsiveHeading variant={2} marginBottom={40}>
          {heading}
        </ResponsiveHeading>
        {bulletPoints.map((bulletPoint, idx) => (
          <Flex key={idx} marginBottom={8} alignItems="center">
            <StyledCheckmark />
            <ResponsiveBody variant={1}>{bulletPoint}</ResponsiveBody>
          </Flex>
        ))}
      </SectionLarge>
    </ShowOn>
  </>
);
