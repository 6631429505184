import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import { getLegalCopy } from 'page-components/index/hero/content';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React, { FC } from 'react';
import styled from 'styled-components';

import { HeroProps } from '.';
import { body, bulletPoints, heading } from './content';
import { BookConsultationButton } from './shared';

const HeroWrapper = styled.div`
  max-width: 1648px;
  margin: auto;
  padding: 80px 40px 0;
`;

const Text = styled(Flex)`
  width: 48%;
`;

const Image = styled.div`
  width: 50%;
`;

export const HeroLarge: FC<HeroProps> = ({ affiliate }) => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <HeroWrapper>
      <Flex>
        <Text column>
          <ResponsiveHeading variant={1} marginBottom={24}>
            {heading}
          </ResponsiveHeading>
          <ResponsiveBody variant={1} marginBottom={40}>
            {body}
          </ResponsiveBody>
          <Flex marginBottom={24} column>
            {bulletPoints.map((bulletPoint, idx) => (
              <Flex key={idx} marginBottom={8} alignItems="center">
                <StyledCheckmark />
                <ResponsiveBody variant={1}>{bulletPoint}</ResponsiveBody>
              </Flex>
            ))}
          </Flex>
          <BookConsultationButton marginBottom={24} />
          <Body variant={5}>{getLegalCopy(affiliate)}</Body>
        </Text>
        <Image>
          <StaticImage
            src="../../images/affiliate-hero-large.webp"
            alt="smiling-couple"
          />
        </Image>
      </Flex>
    </HeroWrapper>
  </ShowOn>
);
