import {
  ButtonColor,
  PrimaryButton,
  PrimaryButtonProps,
} from 'component-library/components/buttons/PrimaryButton';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import React, { FC } from 'react';

export const BookConsultationButton: FC<Omit<
  PrimaryButtonProps,
  'buttonColor'
>> = ({ ...rest }) => (
  <LinkWrapper to="/welcome/sign-up">
    <PrimaryButton withCaret buttonColor={ButtonColor.Peach} {...rest}>
      Book a Free Consultation
    </PrimaryButton>
  </LinkWrapper>
);
