import { AffiliateLanguage } from 'page-components/affiliate/affiliateUtils';
import React, { FC } from 'react';

import { HeroLarge } from './HeroLarge';
import { HeroSmall } from './HeroSmall';

export interface HeroProps {
  affiliate: AffiliateLanguage;
}

export const Hero: FC<HeroProps> = (props) => (
  <>
    <HeroLarge {...props} />
    <HeroSmall {...props} />
  </>
);
