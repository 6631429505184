import { useLocation } from '@reach/router';
import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { AffiliateWelcomeQuery } from 'graphqlTypes';
import { getUrlTrackingDetails } from 'helpers/getUtmDataFromUrl';
import { Hero, PeaceOfMind, WhoQualifies } from 'page-components/affiliate';
import { getAffiliateDetails } from 'page-components/affiliate/affiliateUtils';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC, useEffect } from 'react';
import { adConversionEvents, track } from 'utils/analytics';

interface AffiliateWelcomeProps {
  data: AffiliateWelcomeQuery;
}

const AffiliateWelcome: FC<AffiliateWelcomeProps> = ({ data }) => {
  const location = useLocation();
  const { source } = getUrlTrackingDetails(location.search);
  const affiliates = data.allContentfulAffiliatePartner.nodes;

  useEffect(() => {
    track('Signup step', {
      step: 'Affiliate Welcome',
    });
    track(adConversionEvents.funnelStarted);
  }, []);

  return (
    <Layout>
      <Navigation simpleNav />
      <PageWrapper background={colors.greenLight}>
        <Hero affiliate={getAffiliateDetails(source, affiliates)} />
        <PeaceOfMind />
        <WhoQualifies />
        <BottomCta
          heading="Spend retirement with more."
          body="From greater peace of mind to ongoing care, we're ready to lend a hand."
        />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default AffiliateWelcome;

export const query = graphql`
  query AffiliateWelcome {
    allContentfulAffiliatePartner {
      nodes {
        ...AffiliatePartner
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Welcome to Retirable | Retirable"
    description="Welcome to Retirable. The first-of-its-kind planning, investing and spending solution built for retirees to make the most out of their golden years."
    imagePath="welcome-featured.png"
  />
);
