import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionLarge } from 'page-components/shared/section/SectionLarge';
import { SectionSmall } from 'page-components/shared/section/SectionSmall';
import React from 'react';

const heading = 'Who qualifies for a free plan?';
const body =
  'Retirable advisors currently work with adults 50+ who are less than 10 years from retiring or already retired, and need help with financial planning and investing. We’ll determine how we can help on our first call based on your situation, goals, and needs.';

export const WhoQualifies = () => (
  <>
    <ShowOn screens={[Screen.Sm, Screen.Md]}>
      <SectionSmall
        backgroundColor={colors.green}
        image={
          <StaticImage
            src="../images/who-qualifies.webp"
            alt="smiling-retirees"
          />
        }
        withImagePadding
      >
        <Heading variant={4} marginBottom={16}>
          {heading}
        </Heading>
        <Body variant={3}>{body}</Body>
      </SectionSmall>
    </ShowOn>
    <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
      <SectionLarge
        backgroundColor={colors.green}
        image={
          <StaticImage
            src="../images/who-qualifies-large.webp"
            alt="smiling-retirees"
            width={600}
          />
        }
        imageAlignment="left"
      >
        <ResponsiveHeading variant={2} marginBottom={64}>
          {heading}
        </ResponsiveHeading>
        <ResponsiveBody variant={1}>{body}</ResponsiveBody>
      </SectionLarge>
    </ShowOn>
  </>
);
